@import url('https://fonts.googleapis.com/css2?family=Mukta');

.fileUploaderContainer {
    display: flex;
    justify-content: center;
    color: white;
}

.dropZoneWrapper,
.dropZoneWrapper>div {
    width: 1065px;
    height: 587px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 95px;
}

.dropZoneWrapper {
    background-color: #1D1D1D;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='95' ry='95' stroke='%23757575FF' stroke-width='7' stroke-dasharray='10%2c 20' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}

.dropZoneActive {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='95' ry='95' stroke='%2374C937FF' stroke-width='7' stroke-dasharray='10%2c 20' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}

.error {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='95' ry='95' stroke='%23FF0303FF' stroke-width='7' stroke-dasharray='10%2c 20' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}

.dropZoneFooter {
    text-align: center;
    color: #757575;
    font-family: 'Mukta';
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 1px;
    margin-top: 40px;
    font-weight: 400;
}

.dropZoneWrapper>div {
    flex-direction: column;
    text-align: center;
}