/* Hide scrollbar for Chrome, Safari and Opera */
.terms-container::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.terms-container {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.terms-container {
    color: white;
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    line-height: 1.6;
    max-width: 800px;
    max-height: 800px;
    overflow-y: auto;
}

.terms-container h1 {
    font-size: 2em;
    margin-bottom: 10px;
    text-align: center;
}

.terms-container h2 {
    font-size: 1.5em;
    margin: 20px 0 10px;
}

.terms-container p {
    margin: 10px 0;
}