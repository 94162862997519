@import url("https://fonts.googleapis.com/css?family=Staatliches");
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue');
@import url('https://fonts.googleapis.com/css2?family=Mukta');

/* six caps
bebas neue *
secular one *
amatic sc *
luckiest guy *
staatliches *
special elite *
bangers *
khand
bungee
cutive mono */


.privacy {
    text-decoration: none;
    color: white;
    cursor: pointer;
}

.tos {
    text-decoration: none;
    color: white;
    cursor: pointer;
}

.contact {
    text-decoration: none;
    color: white;
    cursor: pointer;
}

.footer {
    display: flex;
    justify-content: center;
    /* text-align: center; */

    font-family: 'Mukta';
    font-size: 20px;
    margin-bottom: 1px;
    margin-top: 90px;

    gap: 32px;
}

.contact:hover {
    color: #74C937;
}

.tos:hover {
    color: #74C937;
}

.privacy:hover {
    color: #74C937;
}