/* @import url("https://fonts.googleapis.com/css?family=Staatliches");
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue'); */
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Cutive+Mono&?family=Mukta&family=Inconsolata:wght@900&family=Khand:wght@700&family=Secular+One&family=Staatliches&display=swap');/* six caps

bebas neue *
secular one *
amatic sc *
luckiest guy *
staatliches *
special elite *
bangers *
khand
bungee
cutive mono */
@import url('https://fonts.googleapis.com/css2?family=Mukta');

.title {
    text-align: center;
    color: #FFFFFF;
    font-family: 'Mukta';
    font-size: 60px;
    line-height: 60px;
    margin-bottom: 1px;
    font-weight: 600;
    /* letter-spacing: -1px; */
}

.subtitle {
    text-align: center;
    color: #757575;
    font-family: 'Mukta';
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
    margin-top: 10px;
    margin-bottom: 60px;
}