@import url('https://fonts.googleapis.com/css2?family=Mukta');

.subtext1 {
    color: white;
    font-family: 'Mukta';
    font-size: 30px;
}

.subtext1a {
    color: #74C937;
    font-family: 'Mukta';
    font-size: 30px;
}

.subtext2 {
    color: #757575;
    font-family: 'Mukta';
    font-size: 20px;
}

.dropZoneFileSelect {
    color: #74C937;
    font-size: 50px;
    cursor: pointer;
}

.errorMessage {
    color: white;
    font-size: 50px;
    font-family: 'Mukta';
    margin-top: 60px;
}

.errorSubMessage {
    color: #757575;
    font-size: 20px;
    font-family: 'Mukta';
    width: 430px;
    margin-bottom: 80px;

}

.fileName {
    color: white;
    font-size: 35px;
    font-family: 'Mukta';
    margin-bottom: 15px;
    margin-top: 120px;
    border-bottom: 1px solid white;
}

.percentText {
    color: white;
    font-size: 45px;
    font-family: 'Mukta';
    font-weight: 600;
}

.fileSize {
    color: #757575;
    font-size: 20px;
    font-family: 'Mukta';
    margin-bottom: 70px;
}

.reset {
    background-color: #181818;
    color: white;
    font-size: 35px;
    font-family: Staatliches;
    cursor: pointer;
}

.selectNewFile {
    display: flex;
    gap: 12px;
}

.shortlink {
    color: #74C937;
    font-size: 60px;
    font-family: Staatliches;
}

ul {
    padding: 0;
    margin: 0;
}

li {
    list-style-type: none;
    font-size: 60px;
    font-family: Staatliches;
}

h1 {
    font-size: 40px;
    font-family: Staatliches;
}

.submitButton {
    background-color: #8cdc53;
    cursor: pointer;
    border: none;
    border-radius: 28px;
    color: #181818;
    padding: 7px 120px;
    text-align: center;
    text-decoration: none;
    font-size: 25px;
    font-family: 'Mukta';
    font-weight: 500;
    margin-bottom: 90px;
}

.addNewFile {
    padding-top: 2px;
    color: #757575;
    font-family: 'Mukta';
    font-size: 18px;
    cursor: pointer;
}